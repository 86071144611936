<template>
  <div class="detail-index-bg">
    <div class="detail-index">
      <Crumbs />
      <!-- <div class="detail-index-crumbs">
        <span class="detail-index-crumbs-up" @click="goBack()" style="color:black;cursor:pointer">文化直播</span> /
        <span class="detail-index-crumbs-this">{{ info.title }}</span>
      </div> -->
      <div class="detail-index-top">
        <div class="detail-index-top-content-headPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-top.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-content-footerPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-left">
          <img
            style="width: 100%; height: 100%"
            :src="info.cover"
            alt=""
            v-if="!videoShow"
          />
          <video-player
            v-if="videoShow"
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            style="width: 100%; height: 100%"
            :playsinline="true"
            :options="playerOptions"
          ></video-player>
          <div
            class="detail-index-top-left-background"
            v-if=" info.status == 3"
          ></div>
          <div class="listState" :class="`current${info.status}`">
            <div class="listStateFont" v-if="info.status == 1">
              <img
                class="live-ul-li-banner-icon"
                src="https://image.bookgo.com.cn/%20webculture%2Fjm%2F%E7%BC%96%E7%BB%84%203.png"
                alt=""
              />
              正在直播
            </div>
            <div class="listStateFont" v-else-if="info.status == 2">
              直播预告
            </div>
            <div class="listStateFont" v-else-if="info.status == 3">
              精彩回放
            </div>
          </div>

          <div class="detail-index-top-left-button" v-if="info.status == 1 && videoShow==false">
            <img
              class="detail-index-top-left-button-icon"
              src="https://image.bookgo.com.cn/%20webculture%2Fjm%2F%E6%92%AD%E6%94%BE%402x.png"
              alt=""
              @click="goToLink(info)"
            />
          </div>
          <div
            class="GeneratePlayback"
            v-if="
              info.status == 3 && Object.keys(info.playbackList).length === 0
            "
          >
            回放生成中...
          </div>
          <div
            class="detail-index-top-left-button1"
            v-if="info.status == 3 && Object.keys(info.playbackList).length > 0"
          >
            <img
              class="detail-index-top-left-button-icon"
              src="https://image.bookgo.com.cn/%20webculture%2Fjm%2F%E6%92%AD%E6%94%BE%402x.png"
              alt=""
              @click="goToLink1()"
            />
          </div>
        </div>
        <div class="detail-index-top-right">
          <div class="live-detail-top-title">
            {{ info.title }}
          </div>
          <!--<div
            class="live-detail-top-status live-status-bg1"
            v-if="info.status == 1"
          >
            正在直播
          </div>
          <div
            class="live-detail-top-status live-status-bg2"
            v-if="info.status == 3"
          >
            精彩回顾
          </div>
          <div
            class="live-detail-top-status live-status-bg2"
            v-if="info.status == 2"
          >
            直播预告
          </div>-->
          <div class="live-detail-top-des">
            <ul class="live-detail-top-des-ul">
              <li class="live-detail-top-des-ul-li">
                <div class="live-detail-top-des-ul-li-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_live_time.png"
                    alt=""
                  />
                </div>
                <div class="live-detail-top-des-ul-li-label">直播时间：</div>
                <div>
                  {{ info.start_time | dateFormat1 }}
                </div>
                <div
                  class="live-detail-top-des-ul-li-tableTitle"
                  v-if="info.status == 3"
                ></div>
                <div
                  class="live-detail-top-des-ul-li-number"
                  v-if="info.status == 3"
                >
                  <img
                    class="live-ul-li-banner-numberAfter-icon"
                    src="https://image.bookgo.com.cn/%20webculture%2Fjm%2FClip%202%402x%20(1).png"
                    alt=""
                  />
                </div>
                <div
                  class="live-detail-top-des-ul-li-number-label"
                  v-if="info.status == 3"
                >
                  {{ info.playback_view_count }}
                </div>
              </li>
            </ul>
            <li class="live-detail-top-des-ul-li">
              <div class="tableTitle"></div>
            </li>
            <li class="live-detail-top-des-ul-li">
              <div class="title">
                <!--<div class="title-icon">-->
                <img
                  class="title-icon"
                  src="https://image.bookgo.com.cn/%20webculture%2Fjm%2Fsponser%402x.png"
                  alt=""
                />
                <!--</div>-->
                <div class="title-label">活动单位</div>
              </div>
            </li>

            <li class="live-detail-top-des-ul-li">
              <div class="title1">主办单位：</div>
              <div>
                {{ info.sponsor }}
              </div>
            </li>
            <li class="live-detail-top-des-ul-li" v-if="info.undertaker">
              <div class="title2">承办单位：{{ info.undertaker }}</div>
            </li>
            <li class="live-detail-top-des-ul-li" v-if="info.assists">
              <div class="title2">协办单位：{{ info.assists }}</div>
            </li>

            <!-- <div class="live-detail-top-des-item" ref="wrapper" style="display:flex" v-if="playbackList.length > 1">
              <div class="live-detail-top-des-item-mid"  ref="wrapperChild"
                :class="currentI == i ? 'resources-detail-top-des-status active':'resources-detail-top-des-status'"
                v-for="(item,i) in playbackList" :key="i"
                @click="changeVideo(item.url,i)"
                >
                {{item.name}}
              </div>
            </div> -->
            <div
              class="swiper-father"
              v-if="info.status == 3 && info.playbackList.length > 1"
            >
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    :class="
                      currentI == index
                        ? 'resources-detail-top-des-status active'
                        : 'resources-detail-top-des-status'
                    "
                    v-for="(item, index) in info.playbackList"
                    :key="index"
                    @click="changeVideo(item.url, index)"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div
                class="swiper-button-next"
                v-if="info.status == 3 &&info.playbackList.length > 3"
              ></div>
              <div
                class="swiper-button-prev"
                v-if="info.status == 3 &&info.playbackList.length > 3"
              ></div>
            </div>
            <div
              class="swiper-father"
              v-if="info.status == 1 && info.deviceStream.length > 1"
            >
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    :class="
                      currentI == index
                        ? 'resources-detail-top-des-status active'
                        : 'resources-detail-top-des-status'
                    "
                    v-for="(item, index) in info.deviceStream"
                    :key="index"
                    @click="changeVideo1(item.pull_flv, index)"
                  >
                    {{ item.device_name }}
                  </div>
                </div>
              </div>
              <div
                class="swiper-button-next"
                v-if="info.status == 1 && info.deviceStream.length > 3"
              ></div>
              <div
                class="swiper-button-prev"
                v-if="info.status == 1 && info.deviceStream.length > 3"
              ></div>
            </div>
          </div>
          <div class="live-detail-top-bottom">
            <div class="live-detail-top-bottom-number" v-if="info.status == 2">
              {{ info.reserveCount }}人已预约
            </div>
            <div class="bottom-tableTitle" v-if="info.status == 2"></div>
            <div class="live-detail-top-button" v-if="info.status == 2">
              <a
                href="javascript:void(0)"
                style="color: white"
                @click="handleView()"
                >预约观看</a
              >
            </div>

            <!--<div class="live-detail-top-button" v-if="info.status == 3">
            <a
              href="javascript:void(0)"
              style="color: white"
              @click="handleViewBack()"
              >观看回放</a
            >
          </div>-->
          </div>
          <!-- <div class="live-detail-down-button" v-if="info.status == 3 && VideoStatus == 1">
            <a
              href="javascript:void(0)"
              style="color: #761F1E"
              @click="downUrlCode()"
              >下载回放</a>
          </div> -->
          <!-- <div class="live-detail-top-button" v-if="info.status == 1">
            <a
              href="javascript:void(0)"
              style="color: white"
              @click="handleView()"
              >观看直播</a
            >
          </div> -->
        </div>
      </div>
      <div class="detail-index-bottom">
        <div class="detail-index-bottom-content">
          <div class="detail-index-top-content-footerPic">
            <img
              style="width: 100%; height: 100%"
              src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
              alt=""
            />
          </div>
          <div class="detail-index-bottom-content-top">
            <div class="detail-index-bottom-content-top-title">直播简介</div>
          </div>
          <div class="detail-index-bottom-content-bottom">
            <div class="detail-index-bottom-content-bottom-box">
              <!--<div class="detail-index-bottom-content-bottom-box-top">
                <div class="detail-index-bottom-content-bottom-box-top-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                    alt=""
                  />
                </div>
                <div class="detail-index-bottom-content-bottom-box-top-title">
                  简介
                </div>
              </div>-->
              <div
                class="detail-index-bottom-content-bottom-box-bottom"
                v-html="info.content"
              >
                <div>{{ info.content }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <el-dialog
      :close-on-click-modal="false"
      title="提示"
      :visible.sync="formDialogShow"
      :center="true"
    >
      <Cpopup
        :detailType="detailType"
        :title="popupTitle"
        @handle-closed="handleClosed"
        :detailSrc="detailSrc"
      />
    </el-dialog> -->
    <mydialog
      v-if="formDialogShow"
      :keyword="'扫描二维码预约观看'"
      :isShow="formDialogShow"
      :src="detailSrc"
      @close="formDialogShow = false"
    />
    <mydialog1
      v-if="dialogShow"
      :keyword="'播放地址为空'"
      :isShow1="dialogShow"
      :src="detailSrc"
      @close="dialogShow = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import Cpopup from "../components/Popup";
import Cdetail from "../components/Cdetail";
import Crumbs from "@/views/components/crumbs";
import { queryLiveDetail, genQRCode, queryLiveList } from "@/api/common";
import mydialog from "@/components/myDialog";
import mydialog1 from "@/components/myDialog1";
import moment from "moment";
import 'videojs-contrib-hls'
import { videoPlayer } from "vue-video-player";
import BScroll from "better-scroll";
export default {
  components: { Cdetail, Cpopup, mydialog, mydialog1, videoPlayer, Crumbs },
  data() {
    return {
      id: null,
      info: {},
      detailSrc: "",
      formDialogShow: false,
      dialogShow: false,
      popupTitle: "扫描二维码观看直播",
      detailType: "live",
      live_team_list: [],
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: '16:9',
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // type: "video/mp4", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            // src: "https://audio.bookgo.com.cn/other/1634632857_x.mp4", //url地址
          },
        ],

        poster: "../../static/images/test.jpg", //你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: true,
          fullscreenToggle: true, //全屏按钮
        },
        width: "612px",
        height: "360px",
      },
      currentUrl: "",
      videoShow: false,
      VideoStatus: 1,
      currentI: 0,
      playbackList: [],
      tid: "",
    };
  },
  created() {},
  mounted() {
    document.documentElement.scrollTop = 0;
    this.tid = this.$route.query.tid;
    this.getInfo();
    // this.slide_x();
  },
  watch: {
    $route(newV) {
      document.documentElement.scrollTop = 0;
      this.tid = newV.query.tid;
      this.getInfo();
      // this.slide_x();
    },
  },
  methods: {
    _initScroll() {
      if (!this.scroll) {
        this.scroll = new BScroll(this.$refs.wrapper, {
          startX: 0,
          click: true,
          scrollX: true,
          scrollY: false,
          eventPassthrough: "vertical",
          useTransition: false,
        });
      } else {
        this.scrall.refresh();
      }
    },
    _calculateWidth() {
      let rampageList = this.$refs.wrapperChild.getElementsByClassName(
        "live-detail-top-des-item-mid"
      );
      let initWidth = 0;
      for (let i = 0; i < rampageList.length; i++) {
        const item = rampageList[i];
        initWidth += item.clientWidth;
      }
      this.$refs.wrapperChild.style.width = `${initWidth}px`;
    },
    slide_x() {
      this.$nextTick(() => {
        this._initScroll();
        this._calculateWidth();
      });
    },
    timeStamp2String(time) {
      var datetime = new Date();
      datetime.setTime(time);
      var year = datetime.getFullYear();
      var month =
        datetime.getMonth() + 1 < 10
          ? "0" + (datetime.getMonth() + 1)
          : datetime.getMonth() + 1;
      var date =
        datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
      var hour =
        datetime.getHours() < 10
          ? "0" + datetime.getHours()
          : datetime.getHours();
      var minute =
        datetime.getMinutes() < 10
          ? "0" + datetime.getMinutes()
          : datetime.getMinutes();
      var second =
        datetime.getSeconds() < 10
          ? "0" + datetime.getSeconds()
          : datetime.getSeconds();
      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    handleClosed() {},
    handleViewBack() {
      console.log("进入");
      this.videoShow = true;
      // this.getVideoStatus(this.currentUrl)
      if (this.VideoStatus == 1) {
        return;
      } else if (this.playbackList.length != 0) {
        window.location.href = this.currentUrl;
      }
    },
    //判断是视频还是外部链接
    getVideoStatus(val) {
      var filename = val; //文件路径地址
      var index1 = this.currentUrl.lastIndexOf(".");
      var index2 = filename.length;
      var postf = filename.substring(index1, index2); //获取文件后缀名
      if (
        ".MP4" == postf ||
        ".AVI" == postf ||
        ".WMP" == postf ||
        ".mp4" == postf ||
        ".avi" == postf ||
        ".wmp" == postf
      ) {
        this.VideoStatus = 1;
      } else {
        this.VideoStatus = 0;
      }
    },
    changeVideo(val, i) {
      this.currentI = i;
      this.currentUrl = val;
      this.playerOptions.sources = [
        {
          type: "video/mp4",
          src: this.currentUrl,
        },
      ];
    },
    getInfo() {
      var now = moment().unix();
      // console.log()
      queryLiveDetail({ id: this.tid }).then((res) => {
        console.log(res);
        const regex = new RegExp("<img", "gi");
        res.data.content = res.data.content.replace(
          regex,
          `<img style="max-width: 100%; height: auto;margin:0 auto"`
        );
        this.info = res.data;
        if (res.data.playbackList.length != 0) {
          this.currentUrl = res.data.playbackList[0].url;
          this.playbackList = res.data.playbackList;
          // alert(this.currentUrl)
          this.playerOptions.sources = [
            {
              type: "video/mp4",
              src: this.currentUrl,
            },
          ];
        }
        setTimeout(() => {
          var swiper = new Swiper(".swiper-container", {
            // pagination: '.swiper-pagination',
            nextButton: ".swiper-button-next",
            prevButton: ".swiper-button-prev",
            slidesPerView: 3,
            centeredSlides: false,
            paginationClickable: true,
            spaceBetween: 0,
          });
        }, 50);

        console.log(this.playerOptions.sources);
        this.getVideoStatus(this.currentUrl);
        console.log("-------------");
        console.log(this.playerOptions);
        console.log(this.info);
        this.info.start_time = this.timeStamp2String(
          this.info.start_time * 1000
        );
      });
    },
    handleView() {
      let params = {
        wxapp_name: "culture",
        page:
          "bookgo/culture/live/liveDetail/liveDetail?id=" +
          this.tid +
          "&aid=" +
          process.env.VUE_APP_AID,
        scene: "",
        aid: process.env.VUE_APP_AID,
      };
      this.formDialogShow = true;
      genQRCode(params).then((res) => {
        let pic = res.data;
        this.detailSrc = pic;
      });
    },
    downUrlCode() {
      let url = this.currentUrl;
      // var url = "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4"
      let name = "a.mp4";
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob"; // 返回类型blob
      // 监听进度
      xhr.onprogress = function (e) {
        if (e.lengthComputable) {
          // 文件总体积
          console.log(e.total);
          // 已下载体积
          console.log(e.loaded);
        }
      };
      xhr.onload = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          alert("视频正在下载,请耐心等待");
          let blob = this.response;
          // 转换一个blob链接
          let u = window.URL.createObjectURL(new Blob([blob]));
          let a = document.createElement("a");
          a.download = name;
          a.href = u;
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          a.remove();
          // 释放
          window.URL.revokeObjectURL(u);
        }
      };
      xhr.onerror = function () {
        alert("此视频因版权问题不支持下载");
      };
      xhr.send();
    },
    goBack() {
      let routeUrl = this.$router.resolve({
        path: `/live`,
        query: {
          id: this.$route.query.pid,
          cat_id: this.$route.query.cat_id,
          navId: this.$route.query.navId,
          name: this.$route.query.name,
        },
      });
      window.location = routeUrl.href;
    },
    goToLink(info) {
      //判断当前的直播 链接
      var live_link = info.other_platform_link_web
      this.currentUrl = live_link
      if(live_link){
        var url = live_link.split("?")[0]
        var index1 = this.currentUrl.lastIndexOf(".");
        var index2 = url.length;
        var postf = url.substring(index1, index2); //获取文件后缀名
        console.log(1111,postf,live_link)
        if(postf == '.m3u8' || postf == '.M3U8'){
          this.playerOptions.sources = [{
            type: "application/x-mpegURL", // 类型
            src: this.currentUrl// url地址
          }]
          this.videoShow = true
        }else{
          window.open(live_link);
        }
      }  else {
      this.dialogShow = true;
      }



      // if (this.VideoStatus == 0) {
      //   if (info.other_platform_link) {
      //     window.open(info.other_platform_link);
      //   } else {
      //     this.dialogShow = true;
      //   }
      // } else {
      // }
    },
    goToLink1() {
      window.open(this.currentUrl);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/detail.scss";
@import "../../assets/scss/live_default_detail.scss";
@import "../../assets/scss/dialog.scss";
</style>
<style lang="scss" scoped>
.detail-index-top-right .live-detail-down-button {
  //width: 1.49rem;
  height: 0.5rem;
  background: #f1e8e8;
  border-radius: 0.25rem;
  text-align: center;
  line-height: 0.5rem;
  font-size: 0.16rem;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  color: #761f1e;
  display: inline-block;
  font-weight: 500;
  margin-left: 0.26rem;
}
//.live-detail-top-button{
//display: inline-block;
//color: #761F1E;
//position: absolute;
//bottom: 40px;
//}
</style>
<style>
#vjs_video_3 {
  width: 100%;
  height: 100%;
}
.video-js {
  height: 100%;
}
.resources-detail-top-des-status {
  width: 1.16rem;
  height: 0.42rem;
  background: #ffffff;
  border: 1.5px solid #991c10;
  font-size: 0.18rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  text-align: center;
  line-height: 0.4rem;
  margin-right: 0.2rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  cursor: pointer;
  color: #991c10;
  text-overflow: ellipsis; /*超出部分省略号表示*/
  overflow: hidden;
}
.active {
  background: #991c10;
  color: #fafdfe;
}
</style>
